import { useContext, useEffect, useRef } from 'react';
import { SelectedRulesContext } from '../context/SelectedRulesContext';
import { RulesQuestions } from '../data/Rules';
import { iconToActive } from './Questions';

interface SummaryPropsType {
  dataFetched: boolean;
}

function Summary(props: SummaryPropsType) {
  const { dataFetched } = props;

  const {
    state,
    city,
    zip,
    address,
    estimateType,
    performedFor,
    work,
    contractType,
    contractorType,
    soldtoCustomer,
    customerType,
    excemptionCertificate,
    bHierarchyServAgreement,
    bHierarchyProj,
    scope,
    bHierarchyQS,
    servAgreementScope,
    serviceType,
    servicesQS,
    //charge,
    acceptableCertificate,
    businessHierarchyCertificate,
    chargeResponse,
    tax
  } = useContext(SelectedRulesContext);

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ block: 'end', inline: 'nearest' });
    }
  });

  if (dataFetched) {
    return (
      <div className="col-md-4 pl-0">
        <div className="summary">
          {state && (
            <div key="state" className="qes-ans-block" onClick={() => iconToActive(1)}>
              <div className="right-block-ques">1. {RulesQuestions.state}</div>

              <div className="right-block-ans">{state}</div>
            </div>
          )}

          {city && (
            <div key="city" className="qes-ans-block" onClick={() => iconToActive(2)}>
              <div className="right-block-ques">2. {RulesQuestions.city}</div>

              <div className="right-block-ans">{city}</div>
            </div>
          )}

          {zip && (
            <div key="zip" className="qes-ans-block" onClick={() => iconToActive(3)}>
              <div className="right-block-ques">3. {RulesQuestions.zip}?</div>

              <div className="right-block-ans">{zip}</div>
            </div>
          )}

          {address && (
            <div key="address" className="qes-ans-block" onClick={() => iconToActive(4)}>
              <div className="right-block-ques">4. {RulesQuestions.address}?</div>

              <div className="right-block-ans">{address}</div>
            </div>
          )}

          {estimateType && (
            <div key="estimateType" className="qes-ans-block" onClick={() => iconToActive(4)}>
              <div className="right-block-ques">5. {RulesQuestions.estimateType}</div>

              <div className="right-block-ans">{estimateType}</div>
            </div>
          )}
          {estimateType === 'Project' &&
          (state === 'Texas' || state === 'Iowa' || state === 'North Carolina' || state === 'Kansas') ? (
            <>
              {performedFor && (
                <div key="performedFor" className="qes-ans-block" onClick={() => iconToActive(5)}>
                  <div className="right-block-ques">6. {RulesQuestions.performedFor}</div>

                  <div className="right-block-ans">{performedFor}</div>
                </div>
              )}
              {work && (
                <div key="work" className="qes-ans-block" onClick={() => iconToActive(6)}>
                  <div className="right-block-ques">7. {RulesQuestions.work}</div>

                  <div className="right-block-ans">{work}</div>
                </div>
              )}

              {contractType && (
                <div key="contractType" className="qes-ans-block" onClick={() => iconToActive(7)}>
                  <div className="right-block-ques">8. {RulesQuestions.contractType}</div>

                  <div className="right-block-ans">{contractType}</div>
                </div>
              )}

              {contractorType && (
                <div key="contractorType" className="qes-ans-block" onClick={() => iconToActive(8)}>
                  <div className="right-block-ques">9. {RulesQuestions.contractorType}</div>

                  <div className="right-block-ans">{contractorType}</div>
                </div>
              )}

              {soldtoCustomer && (
                <div key="soldtoCustomer" className="qes-ans-block" onClick={() => iconToActive(9)}>
                  <div className="right-block-ques">10. {RulesQuestions.soldToCustomer}</div>

                  <div className="right-block-ans">{soldtoCustomer}</div>
                </div>
              )}

              {customerType && (
                <div key="customerType" className="qes-ans-block" onClick={() => iconToActive(10)}>
                  <div className="right-block-ques">11. {RulesQuestions.customerType}</div>

                  <div className="right-block-ans">{customerType}</div>
                </div>
              )}

              {excemptionCertificate && (
                <div key="exemptionCertificate" className="qes-ans-block" onClick={() => iconToActive(11)}>
                  <div className="right-block-ques">12. {RulesQuestions.excemptionCertificate}</div>

                  <div className="right-block-ans">{excemptionCertificate}</div>
                </div>
              )}

              {acceptableCertificate['Acceptable Certificate'] && (
                <div key="acceptableCertificaete" className="qes-ans-block">
                  <div className="right-block-ques">Acceptable Ceritificate </div>

                  <div className="right-block-ans">{acceptableCertificate['Acceptable Certificate']}</div>
                </div>
              )}

              {estimateType && estimateType === 'Project' && bHierarchyProj && scope && (
                <div key="bHierarchyProj" className="qes-ans-block" onClick={() => iconToActive(12)}>
                  <div className="right-block-ques">13. {RulesQuestions.bHierarchyProj}</div>

                  <div className="right-block-ans">
                    {bHierarchyProj} - {scope}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {work && (
                <div key="work" className="qes-ans-block" onClick={() => iconToActive(5)}>
                  <div className="right-block-ques">6. {RulesQuestions.work}</div>

                  <div className="right-block-ans">{work}</div>
                </div>
              )}

              {contractType && (
                <div key="contractType" className="qes-ans-block" onClick={() => iconToActive(6)}>
                  <div className="right-block-ques">7. {RulesQuestions.contractType}</div>

                  <div className="right-block-ans">{contractType}</div>
                </div>
              )}

              {contractorType && (
                <div key="contractorType" className="qes-ans-block" onClick={() => iconToActive(7)}>
                  <div className="right-block-ques">8. {RulesQuestions.contractorType}</div>

                  <div className="right-block-ans">{contractorType}</div>
                </div>
              )}

              {soldtoCustomer && (
                <div key="soldtoCustomer" className="qes-ans-block" onClick={() => iconToActive(8)}>
                  <div className="right-block-ques">9. {RulesQuestions.soldToCustomer}</div>

                  <div className="right-block-ans">{soldtoCustomer}</div>
                </div>
              )}

              {customerType && (
                <div key="customerType" className="qes-ans-block" onClick={() => iconToActive(9)}>
                  <div className="right-block-ques">10. {RulesQuestions.customerType}</div>

                  <div className="right-block-ans">{customerType}</div>
                </div>
              )}

              {excemptionCertificate && (
                <div key="exemptionCertificate" className="qes-ans-block" onClick={() => iconToActive(10)}>
                  <div className="right-block-ques">11. {RulesQuestions.excemptionCertificate}</div>

                  <div className="right-block-ans">{excemptionCertificate}</div>
                </div>
              )}

              {acceptableCertificate['Acceptable Certificate'] && (
                <div key="acceptableCertificaete" className="qes-ans-block">
                  <div className="right-block-ques">Acceptable Ceritificate </div>

                  <div className="right-block-ans">{acceptableCertificate['Acceptable Certificate']}</div>
                </div>
              )}

              {estimateType && estimateType === 'ServiceAgreement' && bHierarchyServAgreement && servAgreementScope && (
                <div key="bHierarchyServAgreement" className="qes-ans-block" onClick={() => iconToActive(11)}>
                  <div className="right-block-ques">12. {RulesQuestions.bHierarchyServAgreement}</div>

                  <div className="right-block-ans">
                    {bHierarchyServAgreement} - {servAgreementScope}
                  </div>
                </div>
              )}
            </>
          )}

          {/* {estimateType && estimateType === 'ServiceAgreement' && charge.length !== 0 && (
            <div key="charge" className="qes-ans-block" onClick={() => iconToActive(12)}>
              <div className="right-block-ques">12. {RulesQuestions.charge}?</div>

              <div className="right-block-ans">{charge.toString()}</div>
            </div>
          )} */}

          {estimateType &&
            estimateType === 'Project' &&
            bHierarchyProj &&
            scope &&
            state !== 'Texas' &&
            state !== 'Iowa' &&
            state !== 'North Carolina' &&
            state !== 'Kansas' && (
              <div key="bHierarchyProj" className="qes-ans-block" onClick={() => iconToActive(11)}>
                <div className="right-block-ques">12. {RulesQuestions.bHierarchyProj}</div>

                <div className="right-block-ans">
                  {bHierarchyProj} - {scope}
                </div>
              </div>
            )}

          {/* {estimateType && estimateType === 'Project' && serviceType.length !== 0 && (
            <div key="serviceType" className="qes-ans-block" onClick={() => iconToActive(12)}>
              <div className="right-block-ques">12. {RulesQuestions.serviceType}?</div>

              <div className="right-block-ans">{serviceType.toString()}</div>
            </div>
          )} */}

          {/* {estimateType && estimateType === 'Project' && charge.length !== 0 && (
            <div key="charge" className="qes-ans-block" onClick={() => iconToActive(13)}>
              <div className="right-block-ques">13. {RulesQuestions.charge}?</div>

              <div className="right-block-ans">{charge.toString()}</div>
            </div>
          )} */}

          {estimateType && estimateType === 'Quoted Service' && bHierarchyQS && (
            <div key="bHierarchyQS" className="qes-ans-block" onClick={() => iconToActive(11)}>
              <div className="right-block-ques">12. {RulesQuestions.bHierarchyQS}</div>

              <div className="right-block-ans">{bHierarchyQS}</div>
            </div>
          )}

          {/* {estimateType && estimateType === 'Quoted Service' && servicesQS.length !== 0 && (
            <div key="servicesQS" className="qes-ans-block" onClick={() => iconToActive(12)}>
              <div className="right-block-ques">12. {RulesQuestions.servicesQS}?</div>

              <div className="right-block-ans">{servicesQS.toString()}</div>
            </div>
          )} */}

          {/* {estimateType && estimateType === 'Quoted Service' && charge.length !== 0 && (
            <div key="charge" className="qes-ans-block" onClick={() => iconToActive(13)}>
              <div className="right-block-ques">13. {RulesQuestions.charge}?</div>

              <div className="right-block-ans">{charge.toString()}</div>
            </div>
          )} */}

          {(businessHierarchyCertificate['GCK Code'] ||
            businessHierarchyCertificate['Material Number'] ||
            businessHierarchyCertificate['Project Type'] ||
            businessHierarchyCertificate['Taxability Guidance'] ||
            businessHierarchyCertificate['Order Type']) && (
            <>
              <div key="project_Type" className="qes-ans-block">
                <div className="right-block-ques">Project Type</div>

                <div className="right-block-ans">{businessHierarchyCertificate['Project Type']}</div>
              </div>

              <div key="gcK_Code" className="qes-ans-block">
                <div className="right-block-ques">GCK Code</div>

                <div className="right-block-ans">{businessHierarchyCertificate['GCK Code']}</div>
              </div>
              {businessHierarchyCertificate['Order Type'] && (
                <div key="order_Type" className="qes-ans-block">
                  <div className="right-block-ques">Order Type</div>

                  <div className="right-block-ans">{businessHierarchyCertificate['Order Type']}</div>
                </div>
              )}

              <div key="material_Number" className="qes-ans-block">
                <div className="right-block-ques">Material Number</div>
                <div className="right-block-ans">
                  {businessHierarchyCertificate['Material Number']
                    ? businessHierarchyCertificate['Material Number']
                        .split(' | ')
                        .map((split_value) => <p key={split_value}> {split_value} </p>)
                    : ''}
                </div>
              </div>

              <div key="taxability_Guidance" className="qes-ans-block">
                <div className="right-block-ques">Taxability Guidance</div>
                <div className="right-block-ans">
                  {businessHierarchyCertificate['Taxability Guidance']
                    ? businessHierarchyCertificate['Taxability Guidance']
                        .split(' | ')
                        .map((split_value) => <p key={split_value}> {split_value} </p>)
                    : ''}
                </div>
              </div>

              {/* <div key="charges" className="qes-ans-block">
                <div className="right-block-ques">Charges</div>
                <div className="right-block-ans">{chargeResponse.Charge}</div>
              </div> */}

              <div key="tax" className="qes-ans-block">
                <div className="right-block-ques">Tax Rate</div>
                <div className="right-block-ans">
                  {tax['Tax Rate']
                    ? tax['Tax Rate'].split(' | ').map((split_value) => <p key={split_value}> {split_value} </p>)
                    : ''}
                </div>
              </div>
            </>
          )}
          <div ref={scrollRef}></div>
        </div>
      </div>
    );
  }
  return <></>;
}

export default Summary;
