import {
  CSSProperties,
  forwardRef,
  memo,
  Ref,
  useImperativeHandle,
  useState,
} from "react";

export interface ToastRefProps {
  showMessage: (message: string, type?: "success" | "error") => void;
}

function Toast(props: object, ref: Ref<ToastRefProps>) {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState<"success" | "error">(
    "success"
  );

  const styleObject: Record<"success" | "error", CSSProperties> = {
    success: { background: "#00af8e" },
    error: { background: "#ef0137" },
  };

  const classNameObject: Record<"success" | "error", string> = {
    success: "icons-Close-success-m23",
    error: "icons-Close-error-m23",
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        showMessage(
          userMessage: string,
          type: "success" | "error" = "success"
        ) {
          setMessageType(type);
          setMessage(userMessage);

          setTimeout(() => {
            setMessageType("success");
            setMessage("");
          }, 3000);
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      {message ? (
        <div className="toast-message-box" style={styleObject[messageType]}>
          <i className={classNameObject[messageType]}></i>
          <p> {message} </p>
        </div>
      ) : null}
    </>
  );
}

export default memo(forwardRef(Toast));
