import '../../styles/App.css';

function LoaderComp() {
  return (
    <div className="preloader-main">
      <div className="col-sm-2">
        <div className="circle-cover">
          <div id="circle2"></div>
          <p>
            Loading <span>..</span>
          </p>
        </div>
      </div>
    </div>
  );
}
export default LoaderComp;
