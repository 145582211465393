import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { loginApiRequest, msalConfig } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);
const init = msalInstance.initialize();

axios.defaults.baseURL = 'https://taxrulesus.azurewebsites.net/api/';
axios.interceptors.request.use(
  async (response) => {
    const account = msalInstance.getAllAccounts()[0];
    const msalResponse = await msalInstance.acquireTokenSilent({
      ...loginApiRequest,
      account: account
    });
    response.headers['Authorization'] = `Bearer ${msalResponse.accessToken}`;
    return response;
  },
  (err) => {
    console.error('MSAL Error', err);
    return Promise.reject(err);
  }
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
  // </React.StrictMode>
);
