import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { loginApiRequest } from './authConfig';
import Preloader from './components/common/Preloader';
import HomePage from './pages/Home';

function App() {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None && accounts.length === 0) {
      instance.loginRedirect(loginApiRequest).catch((e) => {
        console.log(e);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);

  if (isAuthenticated) {
    return (
      <div className="App">
        <HomePage />
      </div>
    );
  }
  // preloader
  return <Preloader />;
}

export default App;
