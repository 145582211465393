import { useState } from 'react';

export type DROPDOWN_TYPE = 'single' | 'multiple';

interface OptionProps {
  id: number;
  value: string;
  definition?: string;
}

export interface DropdownPropsType {
  name: string;
  question?: string;
  options: Array<OptionProps>;
  updateValue?: (key: string, value: string) => void;
  handleOptionClick?: () => void;
  state?: any;
  setState?: any;
  grid?: boolean;
  type?: DROPDOWN_TYPE;
  mode?: 'autocomplete' | 'dropdown';
}

function Dropdown(props: DropdownPropsType) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltiptext, setTooltipText] = useState<string | undefined>(undefined);
  const [inputValue, setInputValue] = useState<string>('');

  const type = props.type || 'single';
  const mode = props.mode || 'dropdown';

  const toggleDropdown = (): void => {
    setIsOpen(!isOpen);
    setInputValue('');
  };

  const handleTooltip = (tooltiptext: string) => {
    return <div className="hover-msg">{tooltiptext}</div>;
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    setIsOpen(true); // Open the dropdown when the user starts typing
  };

  const handleOptionClick = (selectedOption: string) => {
    if (type === 'single') {
      props.setState(selectedOption);

      if (props.handleOptionClick) {
        props.handleOptionClick();
      }
      setIsOpen(false);
    } else {
      props.setState((prevOptions: Array<string>) => {
        return prevOptions.includes(selectedOption)
          ? prevOptions.filter((item: string) => item !== selectedOption)
          : [...prevOptions, selectedOption];
      });
    }
    setInputValue('');
    // setIsOpen(false);
    setShowTooltip(false);
  };

  const filteredOptions = props.options.filter((option) =>
    option.value.toLowerCase().includes(inputValue.toLowerCase())
  );

  const defaultValue = () => {
    let currentValue = 'Select your option';
    if (type === 'single') {
      currentValue = props.state || 'Select your option';
    } else {
      currentValue = props.state.length !== 0 ? props.state.toString() : 'Select your option';
    }
    return currentValue;
  };

  return (
    <div id={props.name} className="select-menu">
      <p className="question">{props.question}</p>

      {mode === 'dropdown' && (
        <div className="select-btn" onClick={toggleDropdown}>
          <span className="sBtn-text">{defaultValue()}</span>
          <i className={`fa-solid fa-chevron-${isOpen ? 'up' : 'down'}`}></i>
        </div>
      )}

      {mode === 'autocomplete' && (
        <div className="select-btn" onClick={toggleDropdown}>
          <input
            className="input-field"
            type="text"
            value={inputValue}
            onChange={(e) => handleInputChange(e.target.value)}
            onClick={toggleDropdown}
            placeholder="Type to search..."
          />
          <i className={`fa-solid fa-chevron-${isOpen ? 'up' : 'down'}`}></i>
        </div>
      )}

      {isOpen && (
        <>
          <ul className={!props.grid ? 'options_test' : 'options'}>
            {filteredOptions.map((option: OptionProps) => (
              <li
                className="option"
                key={option.id}
                onClick={() => handleOptionClick(option.value)}
                onMouseEnter={() => {
                  setTooltipText(option.definition);
                  setShowTooltip(true);
                }}
                onMouseLeave={() => setShowTooltip(false)}
              >
                {type === 'multiple' && (
                  <input
                    type="checkbox"
                    checked={props.state.includes(option.value)}
                    onClick={() => handleOptionClick(option.value)}
                    onChange={() => handleOptionClick(option.value)}
                    style={{ marginRight: '5px' }}
                  />
                )}
                <span className="option-text">{option.value}</span>
              </li>
            ))}
          </ul>
        </>
      )}
      {showTooltip && tooltiptext && handleTooltip(tooltiptext)}
    </div>
  );
}

export default Dropdown;
