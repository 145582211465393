import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';

export interface StepProgressType {
  id: number;
  iconNumber: number;
  nextIconNumber: number | null;
  isCompleted: boolean;
}

export interface StepProgressPropsType {
  items: Array<StepProgressType>;
  setState: Dispatch<SetStateAction<number>>;
  iconsListToShow: Array<number>;
}

function StepProgress(props: StepProgressPropsType) {
  const { items, setState, iconsListToShow } = props;
  const [hoveredIcon, setHoveredIcon] = useState<number | null>(null);

  const handleIconClick = (event: MouseEvent) => {
    const { id } = event.currentTarget;

    // let completed: boolean = true;

    // Object.values(items).forEach((element) => {
    //   let found: boolean = false;
    //   if (element.id < Number(id) && !element.isCompleted && !found) {
    //     completed = false;
    //     found = true;
    //   }
    // });

    // if (completed) {
    setState(Number(id));
    // }
  };

  return (
    <>
      <div className="nav-icons">
        {iconsListToShow.map((key, index) => {
          const item = items.find((element) => element.id === key);

          return (
            <div
              className={`navigation-icon ${hoveredIcon !== null && index + 1 <= hoveredIcon ? 'active' : ''}`}
              id={String(index + 1)}
              key={key}
              onClick={(e) => handleIconClick(e)}
              onMouseEnter={() => setHoveredIcon(index + 1)}
              onMouseLeave={() => setHoveredIcon(null)}
            >
              <span className="nav-number">
                {hoveredIcon !== null && index + 1 === hoveredIcon ? (
                  index + 1
                ) : item?.isCompleted ? (
                  <b>🗸</b>
                ) : (
                  index + 1
                )}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default StepProgress;
