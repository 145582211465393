import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ResponseTypes } from '../../types/ResponseType';

interface ResultPropsType {
  result: object;
  setResult?: Dispatch<SetStateAction<ResponseTypes>>;
  fetchFunctionResponse?: Promise<ResponseTypes>;
  removeKey?: string[];
}

function Result(props: ResultPropsType) {
  const { result, setResult, fetchFunctionResponse } = props;
  const [value, setValue] = useState<Record<string, string>>({});
  const [removeKey, setRemoveKey] = useState<string[]>([]);
  // let removeKey: string[] = [];

  useEffect(() => {
    const resultObject = { ...result };

    if (props.removeKey) {
      setRemoveKey(props.removeKey);
    } else {
      setRemoveKey([]);
    }

    if (!fetchFunctionResponse || !setResult) {
      setValue(resultObject);
      return;
    }

    fetchFunctionResponse.then((response: ResponseTypes) => {
      if ('error' in response) {
        return;
      }

      Object.assign(resultObject, response);
      setResult(resultObject as ResponseTypes);
      setValue(resultObject);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {Object.keys(value).map(
        (key) =>
          !removeKey.includes(key) && (
            <div key={key} className="col-md-6">
              <div className="line-2">
                <h3 className="lineup-head">{key.toUpperCase()}</h3>
                <div className="content-body">
                  <i className="fa-solid fa-globe line-icon"></i>
                  <p key={key} className="lineUp-2">
                    {value[key]
                      ? value[key].split(' | ').map((split_value: string, split_index: number) => (
                          <span key={split_index}>
                            {split_value
                              ? split_value
                                  .split(':')
                                  .map((split_text: string, index: number) =>
                                    split_value.split(':').length > 1 && index === 0 ? (
                                      <b key={key + '_' + String(split_index)}>{split_text}:</b>
                                    ) : (
                                      split_text
                                    )
                                  )
                              : ''}
                            <br />
                          </span>
                        ))
                      : ''}
                  </p>
                </div>
              </div>
            </div>
          )
      )}
    </>
  );
}

export default Result;
