import axios from 'axios';
import { API_URL, BEARER_TOKEN } from '../constants/api';
import {
  AcceptableCertificateResponseType,
  BusineeHierarchyCertificateResponseType,
  ChargeResponseType,
  CityResponseType,
  ErrorResponseType,
  InitialDataResponseType,
  ScopeResponseType,
  ServiceResponseType,
  TaxResponseType,
  ZipResponseType
} from '../types/ResponseType';

// * -------------- Headers declaration for Request API Begin---------------------------
interface HeaderType {
  accessControlCredential: string;
  accessControlOrigin: string;
  apiKey: string;
}

const HeaderFields: HeaderType = {
  accessControlCredential: 'Access-Control-Allow-Credentials',
  accessControlOrigin: 'Access-Control-Allow-Origin',
  apiKey: 'XApiKey'
};

const HeaderValues: HeaderType = {
  accessControlCredential: '*',
  accessControlOrigin: '*',
  apiKey: BEARER_TOKEN
};

const requestHeaders = {
  [HeaderFields.accessControlCredential]: HeaderValues.accessControlCredential,
  [HeaderFields.accessControlOrigin]: HeaderValues.accessControlOrigin,
  [HeaderFields.apiKey]: HeaderValues.apiKey
};
// * -------------- Headers declaration for Request API End---------------------------

export async function getBaseData(): Promise<InitialDataResponseType | ErrorResponseType> {
  try {
    const { data, status } = await axios.get(API_URL + '/stateList', {
      headers: requestHeaders
    });

    if (status === 200) {
      console.info('BaseResponse success');
      return data;
    } else {
      console.error('Unexpected error status code:', status);
      return { error: 'An unexpected error occurred', statusCode: status };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred' };
    }
  }
}

export async function getCity(state: string): Promise<CityResponseType | ErrorResponseType> {
  let city: CityResponseType = {
    'City Name': []
  };
  try {
    const { data, status } = await axios.get(
      `https://taxrulesus.azurewebsites.net/api/statecityzipmapping/${encodeURIComponent(state)}`,
      {
        headers: requestHeaders
      }
    );

    if (status === 200) {
      console.info('City success');
    }
    city['City Name'] = data;
    return city;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred' };
    }
  }
}

export async function getZip(state: string, city: string): Promise<ZipResponseType | ErrorResponseType> {
  let zip: ZipResponseType = {
    Zip: []
  };

  try {
    const { data, status } = await axios.get(
      `https://taxrulesus.azurewebsites.net/api/statecityzipmapping/${encodeURIComponent(state)}/${encodeURIComponent(
        city
      )}`,
      {
        headers: requestHeaders
      }
    );

    if (status === 200) {
      console.info('Zip success');
    }
    zip['Zip'] = data;
    return zip;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred' };
    }
  }
}

export async function getAcceptableCertificate(
  estimateType: string,
  state: string,
  customerType: string
): Promise<AcceptableCertificateResponseType | ErrorResponseType> {
  let acceptablecertificate: AcceptableCertificateResponseType = {
    'Acceptable Certificate': 'No results found'
  };
  try {
    const { data, status } = await axios.get(
      API_URL + `/${encodeURIComponent(estimateType)}/${encodeURIComponent(state)}/${encodeURIComponent(customerType)}`,
      {
        headers: requestHeaders
      }
    );

    if (status === 200) {
      console.info('AcceptableCertificateResponse success');
    }

    if (status === 204 || data['acceptable_Certificates'] === null) {
      return acceptablecertificate;
    }

    acceptablecertificate['Acceptable Certificate'] = data['acceptable_Certificates'];

    return acceptablecertificate;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred' };
    }
  }
}

export async function getBusinessHierarchyCertificate(
  scope: string,
  state: string,
  bHierarchyAgreement: string
): Promise<BusineeHierarchyCertificateResponseType | ErrorResponseType> {
  let busineeHierarchyCertificate: BusineeHierarchyCertificateResponseType = {
    'Project Type': 'No results found',
    'GCK Code': 'No results found',
    'Material Number': 'No results found',
    'Taxability Guidance': 'No results found',
    'Material Taxation': 'No results found',
    'Order Type': 'No results found'
  };

  try {
    const { data, status } = await axios.get(
      API_URL + `/${encodeURIComponent(scope)}/${encodeURIComponent(state)}/${encodeURIComponent(bHierarchyAgreement)}`,
      {
        headers: requestHeaders
      }
    );

    if (status === 200) {
      console.info('BusineeHierarchyCertificateResponse success');
    }

    if (data.length === 0) {
      return busineeHierarchyCertificate;
    }

    busineeHierarchyCertificate['Project Type'] = data[0]['project_Type'];
    busineeHierarchyCertificate['GCK Code'] = data[0]['gcK_Code'];
    busineeHierarchyCertificate['Material Number'] = data[0]['material_Number'];
    busineeHierarchyCertificate['Taxability Guidance'] = data[0]['taxability_Guidance'];
    busineeHierarchyCertificate['Material Taxation'] = data[0]['material_Taxation'];
    busineeHierarchyCertificate['Order Type'] = data[0]['order_Type'];
    return busineeHierarchyCertificate;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred' };
    }
  }
}

export async function getTax(
  city: string,
  state: string,
  zip: string,
  address: string
): Promise<TaxResponseType | ErrorResponseType> {
  let tax: TaxResponseType = {
    'Tax Rate': 'No results found'
  };

  try {
    const { data, status } = await axios.get(
      `https://taxrulesus.azurewebsites.net/api/Tax/CalculateTax/${encodeURIComponent(city)}/${encodeURIComponent(
        state
      )}/${encodeURIComponent(zip)}/${encodeURIComponent(address)}`,

      {
        headers: requestHeaders
      }
    );

    if (status === 200) {
      console.info('TaxResponse Success');
    }

    tax['Tax Rate'] = `Sales Tax: ${data['salesTax']} | Use Tax: ${data['useTax']}`;
    return tax;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);

      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);

      return { error: 'An unexpected error occurred' };
    }
  }
}

// export async function getTax(city: string, state: string, zip: string): Promise<TaxResponseType | ErrorResponseType> {
//   const tax: TaxResponseType = {
//     'Sales Tax Rate': 'No results found',
//     'Use Tax Rate': 'No results found'
//   };

//   const urls = [
//     `https://taxrulesus.azurewebsites.net/api/Tax/CalculateSalesTax/${encodeURIComponent(city)}/${encodeURIComponent(
//       state
//     )}/${encodeURIComponent(zip)}`,
//     `https://taxrulesus.azurewebsites.net/api/Tax/CalculateUseTax/${encodeURIComponent(city)}/${encodeURIComponent(
//       state
//     )}/${encodeURIComponent(zip)}`
//   ];

//   try {
//     for (const url of urls) {
//       const { data, status } = await axios.get(url, {
//         headers: requestHeaders
//       });

//       if (status === 200) {
//         console.info(`${url} - TaxResponse Success`);
//       }

//       if (url.includes('CalculateSalesTax')) {
//         tax['Sales Tax Rate'] = String(data);
//       } else if (url.includes('CalculateUseTax')) {
//         tax['Use Tax Rate'] = String(data);
//       }
//     }
//     console.log(tax);
//     return tax;
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       console.error('axios error message:', error.message);
//       return { error: error.message, statusCode: (error as AxiosError).response?.status || 500 };
//     } else {
//       console.error('unexpected error:', error);
//       return { error: 'An unexpected error occurred' };
//     }
//   }
// }

export async function getCharges(
  state: string,
  bHierarchyAgreement: string,
  charges: string
): Promise<ChargeResponseType | ErrorResponseType> {
  let charge: ChargeResponseType = {
    Charge: 'No results found'
  };

  const chargeSplit = charges.split(',');
  if (chargeSplit.includes('NA')) {
    return { Charge: 'Not Applicable' };
  }

  try {
    const { data, status } = await axios.get(
      API_URL +
        `/Charges/${encodeURIComponent(state)}/${encodeURIComponent(bHierarchyAgreement)}/${encodeURIComponent(
          charges
        )}`,
      {
        headers: requestHeaders
      }
    );

    if (status === 200) {
      console.info('ChargesResponse success');
    }

    if (data.length === 0) {
      return charge;
    }
    // if (data === 'NA') {
    //   charge.Charge = data;
    //   return charge;
    // }

    charge.Charge = data.charges.join(' | ');

    return charge;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred' };
    }
  }
}

export async function getServiceTax(
  state: string,
  allService: string,
  estimateType: string
): Promise<ServiceResponseType | ErrorResponseType> {
  let services: ServiceResponseType = {
    'Service Type': 'No results found'
  };

  try {
    const { data, status } = await axios.get(
      API_URL +
        `/Services/${encodeURIComponent(state)}/${encodeURIComponent(estimateType)}/${encodeURIComponent(allService)}`,
      {
        headers: requestHeaders
      }
    );

    if (status === 200) {
      console.info('ServicesResponse success');
    }

    if (data.length === 0) {
      return services;
    }
    services['Service Type'] = data.services.join(' | ');
    // services.Service = data.service.join(' | ');

    return services;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred' };
    }
  }
}

export async function getWorkScope(bHierarchyProj: string): Promise<ScopeResponseType | ErrorResponseType> {
  let scope: ScopeResponseType = {
    Scope: []
  };
  try {
    const { data, status } = await axios.get(
      API_URL + `/BusinessHierarchyProjectMapping/${encodeURIComponent(bHierarchyProj)}`,
      {
        headers: requestHeaders
      }
    );

    if (status === 200) {
      console.info('Scope of work success');
      scope['Scope'] = data;
      return scope;
    } else {
      console.error('Unexpected error status code:', status);
      return { error: 'An unexpected error occurred', statusCode: status };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred' };
    }
  }
}

export async function getServiceAgreementWorkScope(
  bHierarchyService: string
): Promise<ScopeResponseType | ErrorResponseType> {
  let scope: ScopeResponseType = {
    Scope: []
  };
  try {
    const { data, status } = await axios.get(
      API_URL + `/BusinessHierarchyServiceAgreementMapping/${encodeURIComponent(bHierarchyService)}`,
      {
        headers: requestHeaders
      }
    );

    if (status === 200) {
      console.info('Scope of work success');
      scope['Scope'] = data;
      return scope;
    } else {
      console.error('Unexpected error status code:', status);
      return { error: 'An unexpected error occurred', statusCode: status };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred' };
    }
  }
}
