import { ChangeEvent } from 'react';

interface InputPropsType {
  type: string;
  placeHolder: string;
  value: any;
  handleOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  className: string;
}

function InputField(props: InputPropsType) {
  const { type, placeHolder, value, handleOnChange, className } = props;
  return (
    <input
      type={type}
      className={className}
      aria-describedby="emailHelp"
      value={value}
      placeholder={placeHolder}
      onChange={handleOnChange}
    />
  );
}

export default InputField;
