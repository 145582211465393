interface ResultPropsType {
  installType: string;
  scope?: string;
  materialTaxation: string;
  charges: any;
  tax: string;
  city: string;
  state: string;
  zip: string;
  estimateType: string;
  labour: any;
}

function ResultGrid(props: ResultPropsType) {
  const { installType, scope, materialTaxation, charges, tax, city, state, zip, estimateType, labour } = props;

  return (
    <div className="container-fluid">
      <div className="main-source-head">
        <div className="main-section">
          <h1 className="head-main">Input for Si-Quote</h1>
          <h4 className="head-parts">
            <span className="head-parts-1">Install Type:</span>
            <span className="head-parts-2">{installType}</span>
          </h4>
          <h4 className="head-parts">
            <span className="head-parts-1"></span>
            <span className="head-parts-2">{scope}</span>
          </h4>
          <hr className="hr-line"></hr>
          {/* <h4 className="head-parts">
            <span className="head-parts-1">Material Taxation:</span>
            {materialTaxation}
          </h4>
          <hr className="hr-line"></hr> */}
          <h1 className="m1">
            <span className="m1-b">
              Subject to Taxation
              <br />
              <span className="head-parts-11-c">*If any of the below options is checked, it will be taxable</span>
            </span>
          </h1>
          <div className="change-head">
            <h4 className="head-parts-11">
              <span className="head-parts-11-b">Charges:</span>
            </h4>
          </div>
          <div className="m2">
            <span className="head-parts-1"></span>
            <span className="head-parts-22"> {charges}</span>
          </div>
          {estimateType === 'project' && (
            <div>
              {/* <hr className="hr-line"></hr>
              <div className="change-head">
                <h4 className="head-parts-11">
                  <span className="head-parts-11-b">Labor:</span>
                </h4>
              </div>
              <div className="m2">
                <span className="head-parts-1"></span>
                <span className="head-parts-22"> {labour}</span>
              </div> */}
            </div>
          )}
          <hr className="hr-line"></hr>
          <div className="change-head">
            <h4 className="head-parts-11">
              <span className="head-parts-11-b">Tax Rate:</span>
            </h4>
          </div>
          <div className="m2">
            <span className="head-parts-1"></span>
            <span className="head-parts-22">
              {city},{state},{zip}
              <hr className="hr-line"></hr>
            </span>
          </div>
          <div className="m2">
            <span className="head-parts-1"></span>
            <span className="head-parts-22">
              {tax ? tax.split(' | ').map((split_value: string) => <p key={split_value}> {split_value} </p>) : ''}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ResultGrid;
