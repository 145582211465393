import { useState } from 'react';
import logo from '../assets/img/siewlogo.png';
import Questions from '../components/Questions';
import Summary from '../components/Summary';
import SelectedRulesProvider from '../context/SelectedRulesContext';
import '../styles/App.css';

function HomePage() {
  const [dataFetched, setDataFetched] = useState<boolean>(false); // * Show active icon

  return (
    <div className="container-fluid">
      <div className="welcome-image">
        <div className="layer"></div>
        <div className="navbar-section">
          <img alt="" src={logo}></img>
          <h2 className="Proj-header">TAXABILITY DECISION TOOL</h2>
        </div>
        <div className="row">
          {/* <RulesProvider> */}
          <SelectedRulesProvider>
            <Questions dataFetched={dataFetched} setDataFetched={setDataFetched} />
            <Summary dataFetched={dataFetched} />
          </SelectedRulesProvider>
          {/* </RulesProvider> */}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
