import { Dispatch, FC, ReactNode, SetStateAction, createContext, useState } from 'react';
import {
  AcceptableCertificateResponseType,
  BusineeHierarchyCertificateResponseType,
  ChargeResponseType,
  ServiceResponseType,
  TaxResponseType
} from '../types/ResponseType';

type SelectedRulesContextType = {
  state: string;
  setState: Dispatch<SetStateAction<string>>;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  zip: string;
  setZip: Dispatch<SetStateAction<string>>;
  address: string;
  setAddress: Dispatch<SetStateAction<string>>;
  estimateType: string;
  setEstimateType: Dispatch<SetStateAction<string>>;
  performedFor: string;
  setPerformedFor: Dispatch<SetStateAction<string>>;
  work: string;
  setWork: Dispatch<SetStateAction<string>>;
  contractType: string;
  setContractType: Dispatch<SetStateAction<string>>;
  contractorType: string;
  setContractorType: Dispatch<SetStateAction<string>>;
  soldtoCustomer: string;
  setSoldtoCustomer: Dispatch<SetStateAction<string>>;
  customerType: string;
  setCustomerType: Dispatch<SetStateAction<string>>;
  excemptionCertificate: string;
  setExcemptionCertificate: Dispatch<SetStateAction<string>>;
  bHierarchyServAgreement: string;
  setBHierarchyServAgreement: Dispatch<SetStateAction<string>>;
  bHierarchyProj: string;
  setBHierarchyProj: Dispatch<SetStateAction<string>>;
  scope: string;
  setScope: Dispatch<SetStateAction<string>>;
  bHierarchyQS: string;
  setBHierarchyQS: Dispatch<SetStateAction<string>>;
  servAgreementScope: string;
  setServAgreementScope: Dispatch<SetStateAction<string>>;
  serviceType: string[];
  setServiceType: Dispatch<SetStateAction<string[]>>;
  servicesQS: string[];
  setServicesQS: Dispatch<SetStateAction<string[]>>;
  chargeSA: string[];
  setChargeSA: Dispatch<SetStateAction<string[]>>;
  chargeProj: string[];
  setChargeProj: Dispatch<SetStateAction<string[]>>;
  acceptableCertificate: AcceptableCertificateResponseType;
  setAcceptableCertificate: Dispatch<SetStateAction<AcceptableCertificateResponseType>>;
  businessHierarchyCertificate: BusineeHierarchyCertificateResponseType;
  setBusinessHierarchyCertificate: Dispatch<SetStateAction<BusineeHierarchyCertificateResponseType>>;
  chargeResponse: ChargeResponseType;
  setChargeResponse: Dispatch<SetStateAction<ChargeResponseType>>;
  tax: TaxResponseType;
  setTax: Dispatch<SetStateAction<TaxResponseType>>;
  serviceResponse: ServiceResponseType;
  setServiceResponse: Dispatch<SetStateAction<ServiceResponseType>>;
};

type SelectedRulesProviderType = {
  children: ReactNode;
};

export const SelectedRulesContext = createContext<SelectedRulesContextType>({} as SelectedRulesContextType);

const SelectedRulesProvider: FC<SelectedRulesProviderType> = ({ children }) => {
  const [state, setState] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [estimateType, setEstimateType] = useState<string>('');
  const [performedFor, setPerformedFor] = useState<string>('');
  const [work, setWork] = useState<string>('');
  const [contractType, setContractType] = useState<string>('');
  const [contractorType, setContractorType] = useState<string>('');
  const [soldtoCustomer, setSoldtoCustomer] = useState<string>('');
  const [customerType, setCustomerType] = useState<string>('');
  const [excemptionCertificate, setExcemptionCertificate] = useState<string>('');
  const [bHierarchyServAgreement, setBHierarchyServAgreement] = useState<string>('');
  const [bHierarchyProj, setBHierarchyProj] = useState<string>('');
  const [scope, setScope] = useState<string>('');
  const [bHierarchyQS, setBHierarchyQS] = useState<string>('');
  const [servAgreementScope, setServAgreementScope] = useState<string>('');
  const [serviceType, setServiceType] = useState<string[]>([]);
  const [servicesQS, setServicesQS] = useState<string[]>([]);
  const [chargeSA, setChargeSA] = useState<string[]>([]);
  const [chargeProj, setChargeProj] = useState<string[]>([]);
  const [acceptableCertificate, setAcceptableCertificate] = useState<AcceptableCertificateResponseType>({
    'Acceptable Certificate': ''
  });
  const [businessHierarchyCertificate, setBusinessHierarchyCertificate] =
    useState<BusineeHierarchyCertificateResponseType>({
      'Project Type': '',
      'GCK Code': '',
      'Material Number': '',
      'Taxability Guidance': '',
      'Material Taxation': '',
      'Order Type': ''
    });
  const [chargeResponse, setChargeResponse] = useState<ChargeResponseType>({
    Charge: ''
  });
  const [tax, setTax] = useState<TaxResponseType>({
    'Tax Rate': ''
  });
  const [serviceResponse, setServiceResponse] = useState<ServiceResponseType>({
    'Service Type': ''
  });
  return (
    <SelectedRulesContext.Provider
      value={{
        state,
        setState,
        city,
        setCity,
        zip,
        setZip,
        address,
        setAddress,
        estimateType,
        setEstimateType,
        performedFor,
        setPerformedFor,
        work,
        setWork,
        contractType,
        setContractType,
        contractorType,
        setContractorType,
        soldtoCustomer,
        setSoldtoCustomer,
        customerType,
        setCustomerType,
        excemptionCertificate,
        setExcemptionCertificate,
        bHierarchyServAgreement,
        setBHierarchyServAgreement,
        bHierarchyProj,
        setBHierarchyProj,
        scope,
        setScope,
        bHierarchyQS,
        servAgreementScope,
        setServAgreementScope,
        setBHierarchyQS,
        serviceType,
        setServiceType,
        servicesQS,
        setServicesQS,
        chargeSA,
        setChargeSA,
        chargeProj,
        setChargeProj,
        acceptableCertificate,
        setAcceptableCertificate,
        businessHierarchyCertificate,
        setBusinessHierarchyCertificate,
        chargeResponse,
        setChargeResponse,
        tax,
        setTax,
        serviceResponse,
        setServiceResponse
      }}
    >
      {children}
    </SelectedRulesContext.Provider>
  );
};

export default SelectedRulesProvider;
