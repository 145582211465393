import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: '5a7b460f-a5a1-490b-a005-5d0b76d09519', // Client ID
    authority: 'https://login.microsoftonline.com/38ae3bcd-9579-4fd4-adda-b42e1495d55a', // Tenant ID of the React.JS App Registration
    redirectUri: '/login'
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const loginApiRequest = {
  scopes: ['api://c139960c-a23c-44ff-89e3-501e549a4e07/api.scope']
};
